.custom-navbar {
  background: transparent !important;
    border-bottom: 3px solid white;
  }
  
  .custom-navbar .navbar-brand, .custom-navbar .navbar-nav .nav-link {
    color: #12276D !important;
    text-shadow:
    -2px -2px 0 #ffffff,  
    2px -2px 0 #ffffff,
    -2px 2px 0 #ffffff,
    2px 2px 0 #ffffff; 
    margin-right: 50px; 
    text-decoration: none !important;
    font-size: 22px;
  }
  

  .custom-navbar .navbar-nav .nav-link:last-child {
    margin-right: 0;
  }
  
  .custom-navbar .btn-primary {
    background-color: #0255FB;
    border: none;
    color: #12276D !important;
    text-shadow:
    -2px -2px 0 #ffffff,  
    2px -2px 0 #ffffff,
    -2px 2px 0 #ffffff,
    2px 2px 0 #ffffff; 
    padding: 10px 30px !important;
  }
  
  
  @media (max-width: 991px) {
    .custom-navbar .navbar-collapse {
      justify-content: flex-end;
      height: 100vh;
    }

    .custom-navbar .navbar-brand, .custom-navbar .navbar-nav .nav-link{
        margin: 0;
      }

      .custom-navbar .navbar-nav {
        width: 100%;
        justify-content: center; 
      }
    
      .custom-navbar .navbar-nav .nav-link {
        margin: 5px 0; 
      }
  
      .custom-navbar .btn-primary {
        width: 40%; 
        margin-top: 20px; 
        align-self: center; 
      }
  }
  