/* src/Footer.css */
.footer {
  background: repeating-linear-gradient(
    -45deg,
    #ff0000,
    #ff0000 20px,
    #FFDE9E 20px,
    #FFDE9E 60px,
    #499AA5 60px,
    #499AA5 80px
  );
  border-top: 3px solid white;
    color: #12276D;
    padding: 40px 0;
  }

  a {
    color: inherit !important;
    text-decoration: none !important;
  }
  
  a:hover {
    text-decoration: underline !important;
  }
  
  
  .footer h5 {
    color: #12276D;
    text-shadow:
    -1px -1px 0 #ffffff,  
    1px -1px 0 #ffffff,
    -1px 1px 0 #ffffff,
    1px 1px 0 #ffffff; 
    margin-bottom: 20px;
  }
  
  .footer p {
    color: #12276D;
    text-shadow:
    -1px -1px 0 #ffffff,  
    1px -1px 0 #ffffff,
    -1px 1px 0 #ffffff,
    1px 1px 0 #ffffff; 
    font-size: 14px;
  }
  
  .footer ul {
    list-style: none;
    padding: 0;
  }


  
  .footer ul li {
    margin-bottom: 10px;
    color: #12276D;
    text-shadow:
    -1px -1px 0 #ffffff,  
    1px -1px 0 #ffffff,
    -1px 1px 0 #ffffff,
    1px 1px 0 #ffffff; 
  }
  
  .footer .social-icons {
    font-size: 24px;
    color: #ffffff;
  }

  .social-icons img{
    width:30px;
    margin-right: 10px;
  }
  
  .footer .social-icons .fa {
    margin-right: 10px;
  }
  
  .footer .social-icons .fa:hover {
    color: #1e90ff;
  }
  
  .footer-bottom p {
    color: #12276D;
    text-shadow:
    -1px -1px 0 #ffffff,  
    1px -1px 0 #ffffff,
    -1px 1px 0 #ffffff,
    1px 1px 0 #ffffff; 
    font-size: 14px;
    margin-top: 20px;
  }
  
  @media (max-width: 767px) {
    .footer {
      text-align: center;
    }
  
    .footer .social-icons {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  

  @keyframes vibration {
    0% { transform: translateX(0); }
    25% { transform: translateX(-1px); }
    50% { transform: translateX(1px); }
    75% { transform: translateX(-1px); }
    100% { transform: translateX(0); }
  }
  
  .vibration {
    animation: vibration 0.3s infinite;
  }
  