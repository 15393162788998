.section1 {
    padding: 100px 5%;
    background: transparent;
    color: #12276D;
    text-shadow:
    -2px -2px 0 #ffffff,  
    2px -2px 0 #ffffff,
    -2px 2px 0 #ffffff,
    2px 2px 0 #ffffff; 
  }
  
  .section1 .text-section {
    text-align: center;
    font-size: 30px;
  }

  .bort-title{
    font-size:120px;
    margin-bottom: 30px;
  }
  
  .section1 .image-section img {
    max-width: 100%;
    height: auto;
  }
  
  @media (min-width: 992px) {
    .section1 .text-section {
      text-align: left;
    }
    
    .section1 .image-section {
      display: flex;
      justify-content: flex-end;
    }
  }
  

  @keyframes vibration {
    0% { transform: translateX(0); }
    25% { transform: translateX(-2px); }
    50% { transform: translateX(2px); }
    75% { transform: translateX(-2px); }
    100% { transform: translateX(0); }
  }
  
  .vibration {
    animation: vibration 0.3s infinite;
  }
  