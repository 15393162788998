.section4 {
    background-color: transparent;
    padding: 0px 2% 100px 2%; 
    color: #12276D;
    text-shadow:
    -2px -2px 0 #ffffff,  
    2px -2px 0 #ffffff,
    -2px 2px 0 #ffffff,
    2px 2px 0 #ffffff; 

  }
  
  .section4-text {
    margin-bottom: 20px;
    font-size: 22px;
  }

  .section4-title {
    margin-bottom:50px;
  }
  
  .section4-button {
    font-weight: bold;
    background-color: #f0ad4e; 
    border: none;
    padding: 10px 20px;
  }
  
  .section4-button:hover {
    background-color: #ec971f; 
  }
  