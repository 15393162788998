/* src/Section2.css */
.section2 {
    padding: 0px 3% 100px 3%;
    color: #12276D;
    text-shadow:
    -2px -2px 0 #ffffff,  
    2px -2px 0 #ffffff,
    -2px 2px 0 #ffffff,
    2px 2px 0 #ffffff; 
    background-color: transparent; 
  }
  

  a {
    color: inherit !important;
    text-decoration: underline !important;
  }
  
  a:hover {
    text-decoration: underline !important;
  }

  
  .section2 h2 {
    margin-bottom: 1rem;
  }
  
  .chapter-card {
    background-color: #4B9BA7 !important; 
    border: 4px solid white !important;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  .chapter-card:hover {
    transform: scale(1.05); 
  }
  
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .card-title {
    margin-bottom: 2rem !important;
    color: #12276D !important;

  }
  
  .card-text {
    font-size: 1.75rem;
    color: #12276D;
  }


  
  .section2 .chapter-card .card-image {
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
  }
  